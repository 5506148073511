import {ResManService} from "@common/services";
import {Icon} from "@vacasa/react-components-lib";
import React, {useEffect, useRef, useState} from "react";
import {ADMIN_URL, parseNotifications} from "./notifications.utils";
import styles from "./NotificationsContainer.module.scss";
import {NotificationsContainerSkeleton} from "./NotificationsContainerSkeleton";
import {NotificationsItem} from "./NotificationsItem";

const HEADER_CONTAINER_HEIGHT = 46;
export interface INotificationsContainer {
  title: string;
  legacy_reservation_id?: number;
  reservation_email?: string;
}

export const NotificationsContainer: React.FunctionComponent<INotificationsContainer> = (props) => {
  const {title, legacy_reservation_id, reservation_email} = props;
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasOnTop, setHasOnTop] = useState(false);
  const inbox_url = `${ADMIN_URL}/admin/tools/inbox/search?search=${reservation_email}`;
  const notificationsRef = useRef<any>();
  const resManService = new ResManService();
  const notification_list_height = document.documentElement.clientHeight - HEADER_CONTAINER_HEIGHT;

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      const response = await resManService.getNotificationsById(legacy_reservation_id.toString());
      const parsed_notifications = parseNotifications(response?.data?.notifications);
      setNotifications(parsed_notifications);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onScroll = () => {
    //@ts-ignore
    const newScrollTop = notificationsRef?.current?.scrollTop;
    setHasOnTop(newScrollTop > 0);
  };

  useEffect(() => {
    fetchNotifications();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(onScroll, 200);
  }, [notifications]);

  if (loading) {
    return <NotificationsContainerSkeleton height={notification_list_height} />;
  }

  return (
    <div className={styles.notificationContainer}>
      <div className={`${styles.header} ${hasOnTop ? styles.scrolled : ""}`}>
        <span className={styles.title}>{title}</span>
        <a
          className={styles.inbox}
          style={{textDecoration: "none"}}
          rel="noopener noreferrer"
          target="_blank"
          href={inbox_url}
        >
          Inbox <Icon.ExternalLink height={16} width={16} />
        </a>
      </div>

      <div ref={notificationsRef} onScroll={onScroll} style={{overflowY: "auto", height: notification_list_height}}>
        {notifications.length !== 0 ? (
          notifications.map((item, key) => <NotificationsItem key={key} item={item} />)
        ) : (
          <div style={{padding: 8}}>There are no items to display</div>
        )}
      </div>
    </div>
  );
};
