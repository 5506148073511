import moment from "moment";

export const NOTIFICATIONS_URL = process.env.REACT_APP_NOTIFICATIONS_URL ?? "";
export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL ?? "";

export const notificationColors = {
  success: "#6BA342",
  warning: "#DDAD49",
  error: "#D62E4F",
  info: "#0078AB",
  unknown: "#AAAAAA",
};

export const notificationTypeColors = {
  Delivered: "success",
  Resent: "info",
  Sent: "info",
  Queued: "warning",
  Undelivered: "error",
  Error: "error",
  Unknown: "unknown",
};

export const EMAIL_TYPE_ID = 1;
export const PUSH_TYPE_ID = 2;
export const SMS_TYPE_ID = 3;
export const CHANNELS_TYPE_ID = 4;

export const getDetails = (notification) => {
  const statusValues = notification?.steps ? notification.steps.map((item) => item.name) : [];

  //The order of the "ifs" matter

  if (
    statusValues.includes("PROVIDER_FAILED") ||
    statusValues.includes("DEFERRED") ||
    statusValues.includes("HARD_BOUNCE") ||
    statusValues.includes("SOFT_BOUNCE") ||
    statusValues.includes("SPAM") ||
    statusValues.includes("PROVIDER_NOT_DELIVERED") ||
    statusValues.includes("CARRIER_NOT_DELIVERED") ||
    statusValues.includes("USER_PREFERENCE_BOUNCE")
  ) {
    return {
      status: "Undelivered",
      diffDateName: "Error",
      descriptionName: "Error",
      description: "Has occurred an error.",
    };
  }

  if (statusValues.includes("OPEN") || statusValues.includes("CLICK")) {
    return {
      status: "Delivered",
      diffDateName: "Delivered",
      descriptionName: "Opened by recipient",
      description: "Yes",
    };
  }

  if (statusValues.includes("DELIVERED")) {
    return {
      status: "Delivered",
      diffDateName: "Delivered",
      descriptionName: notification.type === "email" ? "Opened by recipient" : undefined,
      description: notification.type === "email" ? "No" : undefined,
    };
  }

  if (
    statusValues.includes("PROVIDER_REQUESTED") ||
    statusValues.includes("PROCESSED") ||
    statusValues.includes("SEND")
  ) {
    return {
      status: "Sent",
      diffDateName: "Sent",
      descriptionName: undefined,
      description: undefined,
    };
  }

  if (statusValues.includes("RESENT_TO") || statusValues.includes("RESENT_FROM")) {
    return {
      stauts: "Resent",
      diffDateName: "Resent",
      descriptionName: undefined,
      description: undefined,
    };
  }

  if (statusValues.includes("PROCESS_ERROR") || statusValues.includes("PROVIDER_VALIDATION_ERROR")) {
    return {
      status: "Error",
      diffDateName: "Error",
      descriptionName: "Error",
      description: "Has occurred an error.",
    };
  }

  if (statusValues.includes("REQUESTED") || statusValues.includes("PROVIDER_QUEUED")) {
    return {
      status: "Queued",
      diffDateName: "Queued for",
      descriptionName: "Description",
      description: "It will be automatically sent in a while, please wait.",
    };
  }

  return {
    status: "Unknown",
    diffDateName: "Unknown",
    descriptionName: undefined,
    description: undefined,
  };
};

export const getDiffDate = (first_date, second_date) => {
  const date = moment(first_date);
  const now = moment(second_date);
  const minutes = now.diff(date, "minutes");

  const numdays = Math.floor(minutes / 1440);
  const numhours = Math.floor((minutes % 1440) / 60);
  const numminutes = Math.floor((minutes % 1440) % 60);

  let diffDateString = "";

  if (numdays === 1) {
    diffDateString += numdays + " day";
  }
  if (numdays > 1) {
    diffDateString += numdays + " days";
  }

  if (numhours === 1) {
    if (numdays > 0) {
      diffDateString += ", ";
    }
    diffDateString += numhours + " hour";
  }

  if (numhours > 1) {
    if (numdays > 0) {
      diffDateString += ", ";
    }
    diffDateString += numhours + " hours";
  }

  if (numminutes === 1) {
    if (numhours > 0 || numdays > 0) {
      diffDateString += ", ";
    }
    diffDateString += numminutes + " minute";
  }

  if (numminutes > 1) {
    if (numhours > 0 || numdays > 0) {
      diffDateString += ", ";
    }
    diffDateString += numminutes + " minutes";
  }

  return diffDateString !== "" ? diffDateString + "." : diffDateString;
};

export const parseNotifications = (notifications) => {
  if (notifications === undefined) {
    return [];
  }

  const new_notifications = notifications
    .sort((a, b) => {
      const a_date = new Date(a.created_at);
      const b_date = new Date(b.created_at);
      return a_date.getTime() < b_date.getTime() ? 1 : -1;
    })
    .map((notification) => {
      const details = getDetails(notification);
      const diffDate = getDiffDate(notification.created_at, new Date());

      return {
        id: notification.id,
        type: notification.type,
        title: notification.notification_name,
        status: details.status,
        date: notification.created_at,
        to: notification.to,
        color: notificationTypeColors[details.status],
        descriptionName: details.descriptionName,
        description: details.description,
        diffDateName: details.diffDateName,
        diffDate,
      };
    });
  return new_notifications;
};
export const getNotificationTypeId = (type: string): number | undefined => {
  const notificationTypes = {
    email: EMAIL_TYPE_ID,
    push: PUSH_TYPE_ID,
    sms: SMS_TYPE_ID,
    channels: CHANNELS_TYPE_ID
  };
  return notificationTypes[type];
};
