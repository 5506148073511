import React, {useState} from "react";
import moment from "moment-timezone";
import IconButton from "@material-ui/core/IconButton";
import {Icon} from "@vacasa/react-components-lib";
import styles from "./NotificationsItem.module.scss";
import {getNotificationTypeId, notificationColors, NOTIFICATIONS_URL} from "./notifications.utils";
import {FrontendConfiguration} from "@common/configuration";

interface INotificationsItem {
  item: {
    id: string;
    type: string;
    title: string;
    date: string;
    status: string;
    color: string;
    to: string;
    descriptionName: string;
    description: string;
    diffDateName: string;
    diffDate: string;
  };
}

export const NotificationsItem: React.FunctionComponent<INotificationsItem> = (props) => {
  const {id, type, title, date, status, color, to, descriptionName, description, diffDateName, diffDate} = props.item;
  const [showDetails, setShowDetails] = useState(false);
  const date_moment = moment(date).tz(FrontendConfiguration.getTimeZone());
  const date_formatted = date_moment.format("MMMM Do YYYY");
  const time_formatted = date_moment.format("hh:mm:ss A z");
  const statusColor = notificationColors[color];
  const notificationTypeId = getNotificationTypeId(type);
  const notification_url = `${NOTIFICATIONS_URL}/activity-search?filter%5Badvanced._id%5D=${id}&filter%5Badvanced.notification_type_id%5D=${notificationTypeId}`;
  return (
    <div className={styles.notificationItemContainer}>
      <div style={{display: "flex", justifyContent: "space-between", padding: 8}}>
        <div id="left_section" style={{display: "flex", alignItems: "center"}}>
          <div id="icon">
            {type === "email" ? (
              <Icon.Mail height={24} width={24} />
            ) : type === "push" ? (
              <Icon.Smartphone height={24} width={24} />
            ) : (
              <Icon.MessageSquare height={24} width={24} />
            )}
          </div>
          <div className={styles.information}>
            <a
              style={{textDecoration: "none"}}
              className={styles.notificationTitle}
              rel="noopener noreferrer"
              target="_blank"
              href={notification_url}
            >
              {title} <Icon.ExternalLink height={16} width={16} />
            </a>
            <div>
              <span style={{display: "inline-flex", marginRight: 4}} className={styles.dateTime}>
                {date_formatted}
              </span>
              <span style={{display: "inline-flex"}} className={styles.dateTime}>
                {time_formatted}
              </span>
            </div>
          </div>
        </div>
        <div id="right_section" style={{display: "flex", alignItems: "center"}}>
          <div>
            <div style={{backgroundColor: statusColor}} className={styles.notificationButton}>
              {status}
            </div>
          </div>
          <div>
            {!showDetails ? (
              <IconButton className={styles.iconEye} onClick={() => setShowDetails(true)} size="small">
                <Icon.Eye height={24} width={24} />
              </IconButton>
            ) : (
              <IconButton className={styles.iconEye} onClick={() => setShowDetails(false)} size="small">
                <Icon.EyeOff height={24} width={24} />
              </IconButton>
            )}
          </div>
        </div>
      </div>
      {showDetails ? (
        <div style={{marginLeft: 40, marginTop: 8, fontSize: 14}}>
          <div style={{display: "flex"}}>
            <div style={{fontWeight: "bold", marginRight: 4}}>Type:</div>
            <div>{type}</div>
          </div>
          <div style={{display: "flex"}}>
            {diffDateName ? <div style={{fontWeight: "bold", marginRight: 4}}>{diffDateName}:</div> : null}
            {diffDate ? <div>{diffDate}</div> : null}
          </div>
          <div style={{display: "flex"}}>
            <div style={{fontWeight: "bold", marginRight: 4}}>To: </div>
            <div style={{color: "#0078AB"}}>{to}</div>
          </div>
          <div style={{display: "flex"}}>
            {descriptionName ? <div style={{fontWeight: "bold", marginRight: 4}}>{descriptionName}:</div> : null}
            {description ? <div>{description}</div> : null}
          </div>
        </div>
      ) : null}
      <div className={styles.divider}/>
    </div>
  );
};
