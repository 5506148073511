import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import styles from "./NotificationsContainer.module.scss";
import {NotifiactionsItemSkeleton} from "./NotificationsItemSkeleton";

export const BREAKPOINT = 414;
export const FIT_ITEM = 60;
export const FAT_ITEM = 82;

export interface INotificationsContainerSkeleton {
  height: number;
}

export const NotificationsContainerSkeleton: React.FunctionComponent<INotificationsContainerSkeleton> = (props) => {
  const width = document.documentElement.clientWidth;
  const items = width > BREAKPOINT ? Math.floor(props.height / FIT_ITEM) : Math.floor(props.height / FAT_ITEM);
  
  return (
    <div className={styles.notificationContainer}>
      <div id="header" className={styles.header}>
        <Skeleton variant="rect" width={160} height={28} />
        <Skeleton variant="rect" width={60} height={22} />
      </div>
      <div id="item_list" style={{height: props.height}}>
        {Array.from({length: items}, (x) => x).map((_, index) => (
          <NotifiactionsItemSkeleton key={index} />
        ))}
      </div>
    </div>
  );
};
