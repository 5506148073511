import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import styles from "./NotificationsItem.module.scss";

export const NotifiactionsItemSkeleton: React.FunctionComponent = (props) => {
  return (
    <div id="item" className={styles.notificationContainer}>
      <div style={{display: "flex", justifyContent: "space-between", padding: 8}}>
        <div id="right_section" style={{display: "flex", alignItems: "center"}}>
          <div id="icon">
            <Skeleton variant="circle" width={24} height={24} />
          </div>
          <div id="information" style={{marginLeft: 8}}>
            <Skeleton variant="rect" width={150} height={20} style={{marginBottom: 2}} />
            <div>
              <Skeleton variant="rect" width={119} height={16} style={{display: "inline-flex", marginRight: 4}} />
              <Skeleton variant="rect" width={112} height={16} style={{display: "inline-flex"}} />
            </div>
          </div>
        </div>
        <div id="left_section" style={{display: "flex", alignItems: "center"}}>
          <Skeleton variant="rect" width={100} height={24} style={{borderRadius: 50}} />
          <Skeleton variant="circle" width={24} height={24} style={{marginLeft: 8}} />
        </div>
      </div>
      <div className={styles.divider}/>
    </div>
  );
};
