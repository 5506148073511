import {FrontendConfiguration} from "@common/configuration";
import * as jwtDecode from "jwt-decode";
import React, {useState} from "react";
import "../../styles/base.module.scss";
import {NotificationsContainer} from "../notifications/NotificationsContainer";

export const App: React.FunctionComponent<any> = () => {
  const [resInfo] = useState(jwtDecode(window.localStorage.getItem(FrontendConfiguration.tokenStorageName)));

  return (
    <NotificationsContainer
      title="Notification logs"
      legacy_reservation_id={resInfo?.legacy_reservation_id.toString()}
      reservation_email={resInfo?.reservation_email}
    />
  );
};
